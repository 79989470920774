import classNames from "classnames";
import React, { Fragment } from "react";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import city from "../../public/images/city.png";

export function CompanyLoader() {
  return (
    <div className="container ">
      <div className="row">
        {[...Array(isTablet ? 9 : 16)].map((el, index) => (
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4" key={index}>
            <div
              className="ph-item p-0 m-0 shadow"
              style={{ borderRadius: "10px", backgroundColor: "#fff" }}
            >
              <div className="ph-col-3 p-0" style={{ backgroundColor: "#fff" }}>
                <div
                  className="ph-picture"
                  style={{ borderRadius: "10px 10px 0px 0px", height: "160px" }}
                ></div>
                <div className="ph-row  pt-2">
                  <div className="ph-col-2 empty"></div>
                  <div className="ph-col-8 big"></div>
                  <div className="ph-col-3 empty"></div>
                </div>
                <div className="ph-row pt-2">
                  <div className="ph-col-4 empty"></div>
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty"></div>
                </div>
                <div className="ph-row pt-2">
                  <div className="ph-col-2 empty"></div>
                  <div className="ph-col-8"></div>
                  <div className="ph-col-2 empty"></div>
                </div>
                <div className="ph-row pt-2">
                  <div className="ph-col-4 empty"></div>
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty"></div>
                </div>
                <div className="ph-row pt-2">
                  <div className="ph-col-2 empty"></div>
                  <div className="ph-col-8"></div>
                  <div className="ph-col-2 empty"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function HomepageBrandLoader() {
  return(
    <div className="container px-0">
    <div
      className=" ph-item p-0 d-flex justify-content-between align-items-center flex-row"
      style={{ 
        backgroundColor: "transparent",
        border: "none",
         }}
    >
      {[...Array(isDesktop ? 6 : 3)].map((el, index) => (
        <div
          className={classNames(
            "py-0",
            { "ph-col-1 px-0": isMobile },
            { "ph-col-2": !isMobile }
          )}
          
        >
          <div className="ph-row px-0">
            <div
              className=" mt-3"
              style={{ width: isMobile ? "100px" :"150px" ,
                height: isMobile ? "100px" :"150px" ,
                borderRadius: "50%" }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  </div>
  );
}

export function VehicleLoader() {
  return (
    <div className="container px-0">
      <div className="row">
        {[...Array(isDesktop ? 6 : isTablet ? 4 : 4)].map((el, index) => (
          <div className="col-md-4 col-sm-6 col-12 mb-4" key={index}>
            <div
              className="ph-item m-0 shadow"
              style={{
                borderRadius: "10px",
                backgroundColor: "#fff",
                padding: "5px 12px 0px 12px",
              }}
            >
              <div className="ph-col-3 p-0" style={{ backgroundColor: "#fff" }}>
                <div className="ph-row  pt-2">
                  <div className="ph-col-10 empty"></div>
                  <div
                    className="ph-col-2 big mb-1"
                    style={{ width: "10px", borderRadius: "5px" }}
                  ></div>
                </div>
                <div
                  className="ph-picture"
                  style={{ borderRadius: "10px", height: "160px" }}
                ></div>
                <div className="ph-row pt-2 pb-2">
                  <div className="ph-col-6 mb-1"></div>
                  <div className="ph-col-6 empty"></div>
                  <div className="ph-col-8 big mb-1"></div>
                  <div className="ph-col-4 empty"></div>
                  <div className="ph-col-8"></div>
                  <div className="ph-col-4 empty"></div>
                </div>
                <hr />
                <div className="ph-row ">
                  <div className="ph-col-4 mb-3 mt-0"></div>
                  <div className="ph-col-4 mb-3 mt-0 empty"></div>
                  <div className="ph-col-4 mb-3 mt-0"></div>
                  <div className="ph-col-4 mb-3 mt-0"></div>
                  <div className="ph-col-4 mb-3 mt-0 empty"></div>
                  <div className="ph-col-4 mb-3 mt-0"></div>
                  <div className="ph-col-4 mb-2 mt-0"></div>
                  <div className="ph-col-4 mb-2 mt-0 empty"></div>
                  <div className="ph-col-4 mb-2 mt-0"></div>
                </div>
                <hr />
                <div className="ph-row">
                  <div className="ph-col-10 py-0 my-0 big "></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export function HomePageVehicleLoader() {
  return (
    <div className="container px-0">
      <div className="row">
        {[...Array(isDesktop ? 4 : isTablet ? 1 : 1)].map((el, index) => (
          <div className="col-md-3 col-sm-6 col-12 mb-4" key={index}>
            <div
              className="ph-item m-0 shadow"
              style={{
                borderRadius: "10px",
                backgroundColor: "#fff",
                padding: "5px 12px 0px 12px",
              }}
            >
              <div className="ph-col-3 p-0" style={{ backgroundColor: "#fff" }}>
                <div className="ph-row  pt-2">
                  <div className="ph-col-10 empty"></div>
                  <div
                    className="ph-col-2 big mb-1"
                    style={{ width: "10px", borderRadius: "5px" }}
                  ></div>
                </div>
                <div
                  className="ph-picture"
                  style={{ borderRadius: "10px", height: "160px" }}
                ></div>
                <div className="ph-row pt-2 pb-2">
                  <div className="ph-col-6 mb-1"></div>
                  <div className="ph-col-6 empty"></div>
                  <div className="ph-col-8 big mb-1"></div>
                  <div className="ph-col-4 empty"></div>
                  <div className="ph-col-8"></div>
                  <div className="ph-col-4 empty"></div>
                </div>
                <hr />
                <div className="ph-row ">
                  <div className="ph-col-4 mb-3 mt-0"></div>
                  <div className="ph-col-4 mb-3 mt-0 empty"></div>
                  <div className="ph-col-4 mb-3 mt-0"></div>
                  <div className="ph-col-4 mb-3 mt-0"></div>
                  <div className="ph-col-4 mb-3 mt-0 empty"></div>
                  <div className="ph-col-4 mb-3 mt-0"></div>
                  <div className="ph-col-4 mb-2 mt-0"></div>
                  <div className="ph-col-4 mb-2 mt-0 empty"></div>
                  <div className="ph-col-4 mb-2 mt-0"></div>
                </div>
                {/* <hr />
                <div className="ph-row">
                  <div className="ph-col-10 py-0 my-0 big "></div>
                </div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export function CityLoader() {
  return (
    <div className="container ">
      <div className="row">
        {[...Array(isDesktop ? 7 : 6)].map((el, index) => (
          <div
            className="ph-item me-2 d-block pt-4"
            key={index}
            style={{
              borderRadius: "20px",
              border: "1.5px solid grey",
              height: "105px",
              width: "120px",
              backgroundColor: "transparent",
            }}
          >
            <div className="ph-col-12 p-0 ">
              <div className="ph-row justify-content-center">
                <img src={city} alt="city-img" width="37" />
                <div
                  className="ph-col-12 mt-3"
                  // style={{ backgroundColor: "#7f8c8d" }}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function ChargingInfraLoader() {
  return (
    <div className="container px-0">
      <div
        className="row ph-item p-0"
        style={{ backgroundColor: "transparent", border: "none" }}
      >
        {[...Array(isDesktop ? 6 : 3)].map((el, index) => (
          <div
            className={classNames(
              "py-0",
              { "ph-col-12": isMobile },
              { "ph-col-4": !isMobile }
            )}
          >
            <div className="ph-row px-0">
              <div
                className="ph-col-12 mt-3 big"
                style={{ height: "60px", borderRadius: "15px" }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function PostLoader() {
  return (
    <div className="row px-0 pt-2">
      <div className="col-12 mb-4">
        <div
          className="ph-item m-0 shadow px-3 py-3"
          style={{
            borderRadius: "20px",
            border: "0px",
            backgroundColor: "#fff",
            padding: "5px 12px 0px 12px",
          }}
        >
          <div className="ph-col-12 p-0" style={{ backgroundColor: "#fff" }}>
            <div className="ph-row pt-2">
              <div
                style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                className="ph-picture mb-2"
              ></div>
              <div className="ph-col-4 mt-3 ms-4 big"></div>
            </div>
            <div
              className="ph-picture"
              style={{ borderRadius: "10px", height: "230px" }}
            ></div>
            <div className="ph-row pt-2 pb-2">
              <div className="ph-col-6 mb-1"></div>
              <div className="ph-col-6 empty"></div>
              <div className="ph-col-8 big mb-1"></div>
              <div className="ph-col-4 empty"></div>
              <div className="ph-col-8"></div>
              <div className="ph-col-4 empty"></div>
            </div>
            <hr />
            <div className="ph-row ">
              <div className="ph-col-4 mb-3 mt-0"></div>
              <div className="ph-col-2 mb-3 mt-0 empty"></div>
              <div className="ph-col-4 mb-3 mt-0"></div>
              <div className="ph-col-4 mb-3 mt-0"></div>
              <div className="ph-col-2 mb-3 mt-0 empty"></div>
              <div className="ph-col-4 mb-3 mt-0"></div>
              <div className="ph-col-4 mb-2 mt-0"></div>
              <div className="ph-col-2 mb-2 mt-0 empty"></div>
              <div className="ph-col-4 mb-2 mt-0"></div>
            </div>
            <hr />
            <div className="ph-row">
              <div className="ph-col-10 py-0 my-0 big "></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function UpcomingShortLoader({ company, shortCompany }) {
  return (
    <Fragment>
      {[...Array(3)].map((el, index) => (
        <div
          className="ph-item p-0 mt-0 mb-3 mx-0"
          style={{ border: "0" }}
          key={index}
        >
          <div className="ph-col-4 px-0 py-0 my-0">
            <div
              className="ph-picture"
              style={{
                borderRadius: "15px",
                height: shortCompany ? "60px" : "90px",
                width: shortCompany ? "60px" : "85px",
              }}
            ></div>
          </div>
          <div className="ph-col-8 big px-2">
            <div className="ph-row pt-2 pb-2">
              <div className="ph-col-6 mb-1"></div>
              <div className="ph-col-6 empty"></div>
              <div className="ph-col-8 big mb-1"></div>
              <div className="ph-col-4 empty"></div>
              <div className="ph-col-10"></div>
              <div className="ph-col-2 empty"></div>
            </div>
          </div>
          {!company && (
            <div className="ph-row px-1" style={{ height: "30px" }}>
              <div className="ph-col-12 mt-3 px-0 big"></div>
            </div>
          )}
        </div>
      ))}
    </Fragment>
  );
}

export function NewsLoader({ company, shortCompany }) {
  return (
    <Fragment>
      {[...Array(3)].map((el, index) => (
        <div className="ph-item p-0 mt-0 mb-3 mx-0" style={{ border: "0" }}>
          <div className="ph-col-4 px-0 py-0 my-0">
            <div
              className="ph-picture"
              style={{
                borderRadius: "15px",
                height: "170px",
                width: "100%",
              }}
            ></div>
          </div>
          <div className="ph-col-7 big ps-4 pe-0">
            <div className="ph-row pt-1 pb-2">
              <div className="ph-col-12 big mb-1"></div>
              <div className="ph-col-4 big mb-2"></div>
              <div className="ph-col-8 empty"></div>
              <div className="ph-col-12 mb-1"></div>
              <div className="ph-col-12 mb-1"></div>
              <div className="ph-col-10"></div>
              <div className="ph-col-2 empty"></div>
              <div className="ph-col-2 big mt-3"></div>
              <div className="ph-col-10 empty"></div>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
}

export function AllNewsLoader() {
  return (
    <div className="container ">
      <div className="row ">
        {[...Array(isTablet ? 8 : 12)].map((el, index) => (
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4" key={index}>
            <div
              className="ph-item p-0 m-0 shadow"
              style={{ borderRadius: "10px", backgroundColor: "#fff" }}
            >
              <div className="ph-col-3 p-0" style={{ backgroundColor: "#fff" }}>
                <div
                  className="ph-picture"
                  style={{ borderRadius: "10px 10px 0px 0px", height: "160px" }}
                ></div>
                <div className="ph-row pt-2 px-3">
                  <div className="ph-col-12 big"></div>
                  <div className="ph-col-10 big"></div>
                </div>
                <div className="ph-row pt-1 px-3">
                  <div className="ph-col-12"></div>
                  <div className="ph-col-12"></div>
                  <div className="ph-col-12"></div>
                  <div className="ph-col-12 mb-2"></div>
                  <div className="ph-col-6 mt-2"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
